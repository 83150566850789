import React, { Component } from 'react';
import OneBNB from "../contracts/OneBNB.json";

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      const {isHome,user_address,user_address_short,contractAddress} = this.props;
      return (



        <header>
        <div className="container">
          <nav className="navbar navbar-expand-md d-flex justify-content-between">
            <a className="navbar-brand" href="index.html">
              <img className="header_logo" src="img/dark-logo.png" alt=""/>
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
              <i className="fa fa-bars"></i>
            </button>
          
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="theme_btn border_btn" target={'_blank'} href={`https://1bnb.business/app/en/binary-tree/${user_address}`}>Binary Tree</a>
                </li>
                <li className="nav-item">
                  <a className="theme_btn" target={'_blank'} href={`https://bscscan.com/address/${contractAddress}`}>View Contract</a>
                </li>
              </ul>
            </div>
            <div className="language_abs disp_inline">
              <div className="dropdown">
                <button className="dropbtn">
                  <img className="main_lang_flag" alt="" src="img/language/en.svg"/> English 
                </button>
                <div className="dropdown-content">
                  <a href="javascript:void">
                    <img alt="" src="img/language/en.svg"/> English </a>
                  <a href="javascript:void">
                    <img alt="" src="img/language/zh.svg"/> 中国 </a>
                  <a href="javascript:void">
                    <img alt="" src="img/language/es.svg"/> Español </a>
                  <a href="javascript:void">
                    <img alt="" src="img/language/ru.svg"/> Русский </a>
                  <a href="javascript:void">
                    <img alt="" src="img/language/it.svg"/> Italian </a>
                  <a href="javascript:void">
                    <img alt="" src="img/language/pt.svg"/> português </a>
                  <a href="javascript:void">
                    <img alt="" src="img/language/ja.svg"/> 日本 </a>
                  <a href="javascript:void">
                    <img alt="" src="img/language/ro.svg"/> Română </a>
                  <a href="javascript:void">
                    <img alt="" src="img/language/de.svg"/> Deutsch </a>
                </div>
              </div>
            </div>
          </nav> 
        </div>
      </header>

      
      );
    }
  }

  export default Header;