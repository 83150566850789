import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import OneBNB from "../../contracts/OneBNB.json";
import getWeb3 from "../../getWeb3";
import { addAddressApi, communityDetailsApi, packageDetailsApi, compoundSubscriptionApi, addressDetailApi, dataDetailsApi, withdrawApi } from '../../utils';
import { Opacity } from '@material-ui/icons';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          loading:true,
          is_connected:false,
          web3: null,
          accounts: null,
          contract: null,
          contractAddress: null,
          contractAddress_Short:'-',
          sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
          userInfo:{},
          networkMain:false,
          YourAddress: '-',
          YourAddressfull: null,
          amountBNB:null,
          user_referer:'-',
          balance:null,
          adminFee:null,
          getDepositLength:0,
          withdrawl_amount:'',
          globalInfo:{
            systemUserCount: 0,
            systemInvest:0,
            systemWithdraw:0
          },
          userDetails:{
            userDeposit:0,
            userWithdraw:0,
            userReferredBy:'-',
            userReferredByFull:'-',
            binaryIncome:0,
            directIncome:0,
            binarySponsorIncome:0,
            walletBalance:0,
            businessForNextRank:0
          },
          currentProfileRank:{},
          nextProfileRank:{},
          communityDetails:{
            downline_list:[]
          },
          allPackages:{},
          selected_package:false,
          UsdToBnb:0,
          range_usd_price:0,
          invest_status:false,
          address_detail:null,
          subscriptionList:null,
          withdrawList:null,
          incomeList:null,
          sponsorTree:null,
          binaryPayoutLog:null,
          address_block_list:[],
          is_sync_show:false,
          disable_address:'0x2E3F39422a9494f4a8DAE547bce3F4A34a42E3541',
          package: [],
          // package: [1,2,3,4,5,6],
          packageIndex:0,
          // admin_new:null,
          userSide:(this.props.match.params.hasOwnProperty('side') && this.props.match.params.side  != null) ? this.props.match.params.side : 0,
          // userSide:'L',
          minimumWithdraw:10,
          maximumWithdraw:100,
          currentSubAmount:0,
          DirectList:{}
        }
      }
      
      componentDidMount = async () => {
        try {
          // Get network provider and web3 instance.
          const web3 = await getWeb3();
          // Use web3 to get the user's accounts.
          //setInterval(async()=>{
            const accounts = await web3.eth.getAccounts();
           
            if(this.state.accounts !== accounts[0]){
            // Get the contract instance.
            const networkId = await web3.eth.net.getId();
            //alert(networkId);
            //if(networkId !== 56) throw "Please connect Mainnet"; 
            const deployedNetwork = OneBNB.networks[networkId];
            const instance = new web3.eth.Contract(
              OneBNB.abi,
              deployedNetwork && deployedNetwork.address,
            );   
            //this.setState({loading:false});
            let user_account = accounts[0];
            //let user_account = '0xf766ee95604AcE11A0dd37606cD177b423826A68';
            //user_account = '0x2E3F39422a9494f4a8DAE547bce3F4A34a42E354';
            //user_account = '0x7148ab97C76233Fc94a0855A5673EA1864AFD6F5';
            // user_account = '0xeD7b359b42ba554Ee6E9ccC07998afB2172B687C';
            // user_account = '0x6eddbd48f5A25969bf35eEd890b6D9EF9F392C27';
            this.setState({ web3, accounts:user_account, contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
            }
         //},1000);
        } catch (error) {
          // Catch any errors for any of the above operations.
          // alert(
          //   error
          // );
          this.setState({networkMain:false});
          //console.error(error);
        }
        //this.setState({loading:false});
        this.usdPriceUpdate();
        // window.ethereum.on('accountsChanged', (accounts) => {
        //   console.log('address changed');
        //   console.log(accounts);
        //   //this.setState(initialState);
        // })
      };
  
      usdPriceUpdate = async() => {
        fetch('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT')
        .then(response => response.json())
        .then(data => {
          //console.log(data);
          this.setState({UsdToBnb:data.price});
        }
        
        );
      }
    
  
      fetchData = async() => {
        const { accounts, contract, contractAddress } = this.state;
        //accounts = '0x6fF605Bb53a862dC731e3112594Cbc7720A18e6c';
        //accounts = '0xBa498a4F5d11d6de8a20533EA94A9C512AD2e28D';
        // accounts = '0x41dC82912494cAFc2854dfe2201CCFFEA5B949fd';
  
        //console.log(this.state.contractAddress);
        let contractAddress_Short = contractAddress.slice(0, 10) + '.....' + contractAddress.slice(contractAddress.length-5, contractAddress.length);
        let YourAddress = accounts.slice(0, 2) + '...' + accounts.slice(accounts.length-2, accounts.length);
        let YourAddressfull = accounts;
        //console.log(accounts);
        //let userInfo = await contract.methods.getUserInfo(accounts).call();
        let userInfo = await contract.methods.userdata(accounts).call();
        //console.log(userInfo);
        if(userInfo.amount){
          userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
          userInfo.amount = parseFloat(userInfo.amount).toFixed(5);
        }
        if(userInfo.totalIncomeNew){
          userInfo.totalIncome = userInfo.totalIncomeNew/1e8;
          userInfo.totalIncome = parseFloat(userInfo.totalIncome).toFixed(5);
        }
        if(userInfo.withdrawanNew){
          userInfo.withdrawan = userInfo.withdrawanNew/1e8;
          userInfo.withdrawan = parseFloat(userInfo.withdrawan).toFixed(5);
        }
  
        //console.log(userInfo);
       
        let user_referer = '-';
        // console.log(userInfo.refferal_code);
        if(userInfo.refferal_code && !/^0x0+$/.test(userInfo.refferal_code)){
          user_referer = userInfo.refferal_code;
          user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
        }
  
        
        let getDepositLength = await contract.methods.getDepositLength(accounts).call();
        let admin_new = await contract.methods.admin().call();
  
        this.setState({ 
          contractAddress_Short,
          YourAddress,
          YourAddressfull,
          userInfo,
          user_referer,
          getDepositLength,
          admin_new
        },async()=>{
          this.communityDetailsApi();        
          this.dataDetailsApi();
          this.packageDetailsApi();
        });     
      }
  
      paginate = (
        totalItems,
        currentPage = 1,
        pageSize = 10,
        maxPages = 10
      ) => {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);
    
        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }
    
        let startPage, endPage;
        if (totalPages <= maxPages) {
            // total pages less than max so show all pages
            startPage = 1;
            endPage = totalPages;
        } else {
            // total pages more than max so calculate start and end pages
            let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
            let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
            if (currentPage <= maxPagesBeforeCurrentPage) {
                // current page near the start
                startPage = 1;
                endPage = maxPages;
            } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                // current page near the end
                startPage = totalPages - maxPages + 1;
                endPage = totalPages;
            } else {
                // current page somewhere in the middle
                startPage = currentPage - maxPagesBeforeCurrentPage;
                endPage = currentPage + maxPagesAfterCurrentPage;
            }
        }
    
        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    
        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
    
        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
      }
    
      getPaginationData = async(url,page,type,current_page,last_page) => {
        if(!url && !page){
          return true;
        }
        this.setState({loading:true});
        this.dataDetailsApi(url,page,type);
      }
  
      communityDetailsApi = async()=>{
        if(!this.state.accounts){
          return false;
        }
        const data = await communityDetailsApi(this.state.accounts);
        //console.log(data);
        //const data = await communityDetailsApi('0x6fF605Bb53a862dC731e3112594Cbc7720A18e6c');
        //const data = await communityDetailsApi('TWC8GqmGNkW8m5gYBz1bYKx58qxM8qzdzf');
        //const data = await communityDetailsApi('TEwD1YadPF1ef388xbPwuCWFNGLFDXcJYp');
        //const data = await communityDetailsApi('0xC5d99c8B7E70a38423E7F4BA709d63686928537C');
        //const data = await communityDetailsApi('0x51ac3516b5964df862ef1F7b212313bF32aA8e35');
        //console.log('communityDetailsApi',data);
        // console.log("communtity",data);
        if(data.status){
          this.setState(({globalInfo})=>
            ({globalInfo : {...globalInfo,
              systemUserCount: data.data.systemUserCount,
              systemInvest:data.data.systemInvest,
              systemWithdraw:data.data.systemWithdraw
            }})
          );
          let userReferredBy = data.data.userReferredBy;
          if(userReferredBy == 'admin'){
            
          }else{
            userReferredBy = data.data.userReferredBy.slice(0, 5) + '...' +data.data.userReferredBy.slice(data.data.userReferredBy.length-5, data.data.userReferredBy.length);
          }
          this.setState(({userDetails})=>
            ({userDetails : {...userDetails,
              userDeposit:data.data.userDeposit,
              userWithdraw:data.data.userWithdraw,
              userReferredByFull:data.data.userReferredBy ? data.data.userReferredBy : null,
              userReferredBy:data.userReferredBy ? userReferredBy : null,
              binaryIncome:data.data.binaryIncome,
              directIncome:parseFloat(data.data.directIncome).toFixed(2),
              binarySponsorIncome:parseFloat(data.data.binarySponsorIncome).toFixed(2),
              walletBalance:parseFloat(data.data.walletBalance).toFixed(2),
              businessForNextRank:parseFloat(data.data.businessForNextRank).toFixed(2),
              roiIncome :data.data.roiIncome,
            }})
          );
          
          this.setState({currentProfileRank:data.data.currentProfileRank,
            minimumWithdraw:parseFloat(data.data.minimumWithdraw).toFixed(2),
            maximumWithdraw:parseFloat(data.data.maximumWithdraw).toFixed(2),
            currentSubAmount:data.data.currentSubAmount?parseFloat(data.data.currentSubAmount).toFixed(2):0,
            nextProfileRank:data.data.nextProfileRank});
  
        }else{
          
          this.setState({loading:false, invest_status:false});
        }
      }
  
      packageDetailsApi = async()=>{
        
        const data = await packageDetailsApi();
        //console.log(data);
        if(data.status){
          this.setState(({globalInfo})=>
            ({globalInfo : {...globalInfo,
              systemUserCount: data.data.systemUserCount,
              systemInvest:data.data.systemInvest,
              systemWithdraw:data.data.systemWithdraw
            }})
          );
        
          this.setState({
            package:data.data});
          
        }else{
          let defaultError = "Some error occurred!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
          this.setState({loading:false});
        }
      }
  
      addressDetailApi = async()=>{
        if(!this.state.accounts){
          return false;
        }
        const data = await addressDetailApi(this.state.accounts);
        //const data = await addressDetailApi('xcdgvxdgesdfdsfwsfr33');
        //console.log(data.data.details);
        if(data.status){
          this.setState({
            address_detail:data.data.details,
            loading:false});
        }else{
          let defaultError = "Some error occurred!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
          this.setState({loading:false});
        }
      }
  
      dataDetailsApi = async(url=null,page=null,type=null)=>{
        if(!this.state.accounts){
          return false;
        }
        //const data = await dataDetailsApi('admin',url,page,type);
        const data = await dataDetailsApi(this.state.accounts,url,page,type);
        //const data = await dataDetailsApi('0xa3c86f5dee63aef205e52bc3b00151634dcd7594',url,page,type);
        //console.log('dataDetailsApi',data);
        if(data.status){
          // let totalIncome = parseFloat(data.data.uplineIncome) +
          // parseFloat(data.data.boardIncome) + parseFloat(data.data.binaryIncome);
          // this.setState(({userDetails})=>
          //   ({userDetails : {...userDetails,
          //     binaryIncome:parseFloat(data.data.binaryIncome).toFixed(2),
          //     directIncome:parseFloat(data.data.directIncome).toFixed(2),
          //     binarySponsorIncome:parseFloat(data.data.binarySponsorIncome).toFixed(2),
          //     walletBalance:parseFloat(data.data.walletBalance).toFixed(2),
          //     businessForNextRank:parseFloat(data.data.businessForNextRank).toFixed(2)
          //   }})
          // );
    
          let subscriptionList = data.data.subscriptionList;
          let subscriptionListPagination = this.paginate(subscriptionList.total,subscriptionList.current_page,subscriptionList.per_page,10);
          subscriptionList.pages = subscriptionListPagination.pages;
    
          let withdrawList = data.data.withdrawList;
          let withdrawListPagination = this.paginate(withdrawList.total,withdrawList.current_page,withdrawList.per_page,10);
          withdrawList.pages = withdrawListPagination.pages;
    
          let incomeList = data.data.incomes;
          let incomeListPagination = this.paginate(incomeList.total,incomeList.current_page,incomeList.per_page,10);
          incomeList.pages = incomeListPagination.pages;
    
          let binaryPayoutLog = data.data.binaryPayoutLog;
          let binaryPayoutLogPagination = this.paginate(binaryPayoutLog.total,binaryPayoutLog.current_page,binaryPayoutLog.per_page,10);
          binaryPayoutLog.pages = binaryPayoutLogPagination.pages;
  
          let DirectList = data.data.directList;
          DirectList= Object.values(DirectList);
          // console.log("binary",data.data.binaryPayoutLog);
          this.setState({
            subscriptionList:data.data.subscriptionList,
            withdrawList:data.data.withdrawList,
            incomeList:data.data.incomes,
            sponsorTree:data.data.sponsorTree,
            binaryPayoutLog:data.data.binaryPayoutLog,
            DirectList,
            loading:false        
          });
        }else{
          let defaultError = "Some error occurred!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
          this.setState({loading:false});
        }
      }
  
      doJoinNow = async () => {
        //let amountUSD = document.getElementById("range_value").innerText;
        // let userInfo = await contract.methods.userdata(this.state.accounts).call();
        let buyStatus = this.state.userInfo.buyStatus;
        let packageIndex = this.state.packageIndex;
        
        let amountUSD = this.state.amountUSD;
       
        //const weiValue = this.state.web3.utils.toWei('1', 'ether');
        //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
        const { accounts, contract } = this.state;
        if(!this.state.selected_package){
          this.props.enqueueSnackbar("Please select a package first!",{ variant: 'error' })        
          return false;
        }
  
        let sponsor_address = this.state.sponsor_address;
        let userInfo = await contract.methods.userdata(accounts).call();
        if(userInfo.refferal_code && !/^0x0+$/.test(userInfo.refferal_code)){
          sponsor_address = userInfo.refferal_code;
        }
        else if(!sponsor_address){
          this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
          this.setState({sponsor_addressError:true});
          return false;
        }
  
        if(!this.state.web3.utils.isAddress(sponsor_address) || /^0x00+$/.test(sponsor_address)){
          this.props.enqueueSnackbar("Sponsor Address is invalid!",{ variant: 'error' })
          this.setState({sponsor_addressError:true});
          return false;
        }

        if(this.state.userInfo?.buyStatus){
            if(this.state.userInfo.lastBuypackage>=packageIndex){
              this.props.enqueueSnackbar("Cannot Buy precious Package",{ variant: 'error' })        
              return false;
            }
            
          }
  
        // if(buyStatus){
        //   this.props.enqueueSnackbar("You only buy once",{ variant: 'error' })
        //   this.setState({sponsor_addressError:true});
        //   return false;
        // }
  
        let getDepositLength = await contract.methods.getDepositLength(sponsor_address).call();
        
        if(sponsor_address != this.state.admin_new){
          if(getDepositLength == 0){
            this.props.enqueueSnackbar("Invalid Referral User!",{ variant: 'error' })
            this.setState({sponsor_addressError:true});
            return false;
          }
        }
        
        let balance = await this.state.web3.eth.getBalance(this.state.accounts);
        let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
        let amountBNB = this.state.amount;
        //amountBNB = parseFloat(amountBNB).toFixed(5);
        //alert(amountBNB);
        
        if(sponsor_address){
          if(balanceEthVal >= amountBNB){
            try {
              this.setState({loading:true});
              const data = await addAddressApi(this.state.accounts,sponsor_address);
             
  
              let weiValue = this.state.web3.utils.toWei(amountBNB.toString(), 'ether');
            //   console.log(sponsor_address,packageIndex,weiValue)
              let invest = await this.state.contract.methods.invest(sponsor_address,packageIndex).send(
                {
                  from: this.state.accounts,
                  value:weiValue
                }
              );
  
              if(invest.status){              
                this.setState({amountBNB:null, range_usd_price:null, loading:false, invest_status:true});
                this.props.enqueueSnackbar("Joined Successfully! Data will be reflected within few minutes!",{ variant: 'success' });
                this.fetchData();
              }else{
                this.setState({loading:false, invest_status:false});
                this.props.enqueueSnackbar('Some Network Error Occurred!');
              }
              
            }
            catch(err) {
              this.setState({loading:false, invest_status:false});
              if (err.message.includes("User denied transaction signature")) {
                // handle the "error" as a rejection
                this.props.enqueueSnackbar(err.message,{ variant: 'error' });
              }else{
                this.props.enqueueSnackbar(err,{ variant: 'error' });
              }
            }          
          }else{
            this.setState({loading:false});
            this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });          
          }        
        }else{
          this.setState({loading:false});
          this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
        }
      }
  
      doWithdrawal = async () => {
        let available_withdraw_balance = this.state.userDetails.walletBalance;
        let minimumWithdraw = this.state.userDetails.minimumWithdraw;
        let maximumWithdraw = this.state.userDetails.maximumWithdraw;
  
        if(available_withdraw_balance < minimumWithdraw){
          this.props.enqueueSnackbar(`Minimum Withdraw Amount requires ${minimumWithdraw} USD!`,{ variant: 'error' });        
          return false;
        }
  
        if(available_withdraw_balance > maximumWithdraw){
          this.props.enqueueSnackbar(`Maximum Withdraw Amount requires ${available_withdraw_balance} USD!`,{ variant: 'error' });        
          return false;
        }
  
  
        //if(parseFloat(this.state.userInfo.amount) > 0){
          try {
            this.setState({loading:true});
            // let withdrawal = await this.state.contract.methods.userWithdrawal().send(
            //   { 
            //     from: this.state.accounts
            //   }
            // );
  
            const withdrawal = await withdrawApi(this.state.accounts,available_withdraw_balance);
  
            if(withdrawal.status){
              this.setState({amountBNB:null,loading:false});
              this.props.enqueueSnackbar("Withdrawal Successfully!",{ variant: 'success' });
              this.fetchData();
            }else{
              let msg = 'Some Network Error Occurred!';
              if(withdrawal.error){
                msg = withdrawal.error;
              }
              this.setState({loading:false});
              this.props.enqueueSnackbar('Some Network Error Occurred!');
            }
          }
          catch(err) {
            this.setState({loading:false});
            if (err.message.includes("User denied transaction signature")) {
              // handle the "error" as a rejection
              this.props.enqueueSnackbar(err.message,{ variant: 'error' });
            }else{
              this.props.enqueueSnackbar(err,{ variant: 'error' });
            }
          }         
        // }else{
        //   this.props.enqueueSnackbar('Insurenderfficient balance!');
        // }
             
      }

    render() {   
        // console.log(this.state.userInfo?.buyStatus)
        let this2 = this;
      return (
        <Frame withFooter={true} withHeader={true} isHome={true} user_address={this.state.YourAddressfull} user_address_short={this.state.YourAddress} contractAddress={this.state.contractAddress}>
          
          {this.state.loading ? (                  
            <div className="loader-container">
              <div className="planetcircle">
                <div className="planetcircle__a"></div>
                <div className="planetcircle__b"></div>
              </div>
            </div>
          ) : (null)}

            <section className="banner_background">
            <div className="container position-relative">
                <div className="banner_text text-center">
                <h1>OneBNB - A Platform to Count On!</h1>
                <p>Explore new investment opportunities, now accessible to everyone.</p>
                </div>
                <div className="text-center">
                <div className="shape1 banner_box">
                    <div className="shap-img"><img src="img/img1.png" alt="" /></div>
                    <span>Total Deposit</span>
                    <h3> {parseFloat(this.state.userDetails.userDeposit ||0).toFixed(4)} BNB</h3>
                </div>
                <div className="shape2 banner_box">
                    <div className="shap-img"><img src="img/img2.png" alt="" /></div>
                    <span>Total Withdrawn</span>
                    <h3>{parseFloat(this.state.userDetails.userWithdraw ||0).toFixed(4)} BNB</h3>
                </div>
                <div className="shape3 banner_box">
                    <div className="shap-img"><img src="img/img3.png" alt="" /></div>
                    <span>Referred By</span>
                    <h3>{this.state.user_referer||''}</h3>
                </div>
                <div className="shape4 banner_box">
                    <div className="shap-img"><img src="img/img4.png" alt="" /></div>
                    <span>Matching Income</span>
                    <h3>{parseFloat(this.state.userDetails.binaryIncome ||0).toFixed(4)} BNB</h3>
                </div>
                <div className="shape5 banner_box">
                    <div className="shap-img"><img src="img/img5.png" alt="" /></div>
                    <span>Roi Income</span>
                    <h3>{parseFloat(this.state.userDetails.roiIncome ||0).toFixed(4)} BNB</h3>
                    
                </div>
                <div className="shape6 banner_box">
                    <div className="shap-img"><img src="img/img6.png" alt="" /></div>
                    <span>Total System Invest</span>
                    <h3>{parseFloat(this.state.globalInfo.systemInvest ||0).toFixed(4)} BNB</h3>
                </div>
                </div>
            </div>
            <h2 className="banner_watter_mark">ONEBNB</h2>
            </section>



            <section className="about_padding pb-4">
            <div className="container">
                <div className="row">
                <div className="col-md-7 col-lg-7">
                    <div className="about_heading_width">
                    <div className="all_heading mb-4">
                        <h2>Meet your investment needs. All on OneBNB.</h2>
                    </div>
                    </div>
                </div>
                <div className="col-md-5 col-lg-5">
                    <p className="mb-0 large_p">OneBNB is an emerging and fastest-growing decentralized investment platform built to invest in BNB. We take market risks head-on to create risk-assessed opportunities for you.</p>
                </div>
                </div>
            </div>
            </section>

            {/* <section className="pb-5">
            <div className="container">
                <div className="row">
                <div className="col-md-4 col-lg-4 col-sm-6">
                    <div className="Information_box bg_color5">
                    <h4>Total Community</h4>
                    <h2>0 BNB</h2>
                    </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-6">
                    <div className="Information_box bg_color2">
                    <h4>Total Invested</h4>
                    <h2>0 BNB</h2>
                    </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-6">
                    <div className="Information_box bg_color3">
                    <h4>Total Income</h4>
                    <h2>0 BNB</h2>
                    </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-6">
                    <div className="Information_box bg_color11">
                    <h4>Total Community</h4>
                    <h2>0 BNB</h2>
                    </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-6">
                    <div className="Information_box bg_color8">
                    <h4>Total Invested</h4>
                    <h2>0 BNB</h2>
                    </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-6">
                    <div className="Information_box bg_color9">
                    <h4>Total Income</h4>
                    <h2>0 BNB</h2>
                    </div>
                </div>
                </div>
            </div>
            </section> */}

            <section className="pb-5 pt-5 background_img">
            <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="all_heading mb-3">
                      <h2>Join Us Now!</h2>
                      </div>
                      <p>Grow your wealth with OneBNB. Diversify your investments with a next-generation platform imparting financial freedom. Select and join with the total amount of BNB generating an appreciable income percentage.</p>
                  
                    
                      
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                  <div className="wallet_ballance_box">
                        <div className="form-group">
                            <label>Sponsor Address</label>
                            {/* <input className="cus_input" type="text"/> */}
                            {this.state.user_referer != '-'?(
                              <input type="text" className="cus_input"
                              readOnly
                              value={this.state.user_referer || '-'}
                              //onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                              />
                          ):(
                              <input type="text"className="cus_input"
                              value={this.state.sponsor_address || ''}
                              onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                              />
                          )}
                        </div>
                      <ul className="trx_btn">
                      {this.state.package.map((amount,key)=>{
                          let view_amount = parseFloat(amount);
                          let class_ = '';
                          if(this.state.userInfo?.buyStatus){
                            
                            if(this.state.userInfo?.buyStatus && parseFloat(this.state.userInfo?.lastbuy)<key){
                              let price = parseFloat(amount);
                              view_amount = parseFloat(amount);                                                
                            }else{
                              class_ = 'disabled';
                            }
                          }
                          // console.log("last buy",this.state.userInfo?.lastBuypackage)
                          if(!this.state.userInfo?.buyStatus){
                              return(
                                <li key={key}>
                                    <button className={this.state.amount==amount?'active':'inactive'+` ${class_}`} 
                                onClick={e => this.setState({amount:amount,packageIndex:key,selected_package:true})}>
                                {/* {view_amount+' USD'} */}
                              {parseFloat(amount)} BNB
                              </button>
                              </li>
                                
      
                              )
                              
                          }
                          return(
                              <li key={key}>
                                {this.state.userInfo?.buyStatus && parseInt(this.state.userInfo?.lastBuypackage)<key?(
                                  <button className={this.state.amount==amount?'active':'inactive'+` ${class_}`} 
                                    onClick={e => this.setState({amount:amount,packageIndex:key,selected_package:true})}>
                                    {/* {view_amount+' USD'} */}
                                    {parseFloat(amount)} BNB
                                  </button> 
                                ):(
                                  <button disabled style={{opacity:.5}} className={this.state.amount==amount?'active':'inactive'+` ${class_}`} 
                                    onClick={e => this.setState({amount:amount,packageIndex:key})}>
                                    {/* {view_amount+' USD'} */}
                                    {parseFloat(amount)} BNB
                                  </button>
                                )}
                                
                              </li>
                          )  

                      })}
                      </ul>
                      
                      <div className="text-center mt-3 mb-4"> 
                          <button disabled={this.state.loading} className="theme_btn btn-block" onClick={this.doJoinNow}>Join Now</button>
                      </div>

                      <p className="mb-2">Address</p>
                      <div className="d-flex d-flex align-items-center">
                      <CopyToClipboard text={`https://1bnb.business/${this.state.YourAddressfull}`}
                        onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })}>
                        <button title="Copy Your Referal Link" className="copy_btn mr-2"><img src="img/copy.png" alt=""/></button>
                        </CopyToClipboard>   
                          <div>
                         
                     <div>{`https://1bnb.business/${this.state.YourAddress}`}</div>
                    </div>
                      </div>
                      </div>
                  </div>
                </div>
            </div>
            </section>
            <section className="pb-5 pt-5">
            <div className="container">
            <div className="teble-box">
            <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                    <a className="nav-link active" data-toggle="tab" href="#table_tab1" role="tab" aria-selected="true">Subscription History</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" data-toggle="tab" href="#table_tab2" role="tab" aria-selected="false">Income List</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" data-toggle="tab" href="#table_tab3" role="tab" aria-selected="false">Withdraw List</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" data-toggle="tab" href="#table_tab4" role="tab" aria-selected="false">Matching Payout Log</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" data-toggle="tab" href="#table_tab5" role="tab" aria-selected="false">Directs Data</a>
                </li>
            </ul>
            <div className="tab-content">
                <div className="tab-pane fade active show" id="table_tab1" role="tabpanel">
                <div className="table_box">
                  <div className="table-responsive dark_table">
                    <table className="table mb-0">
                        <thead>
                        <tr>
                          <th>Price</th>
                          <th>Amount Paid</th>
                          {/* <th>ROI Percentage</th> */}
                          <th>Activation Date</th>
                        </tr>
                        </thead>
                        <tbody>
                          {this.state.subscriptionList ? (
                            this.state.subscriptionList.data.length>0 ? (
                              this.state.subscriptionList.data.map(function(val, index){                        
                                return (
                                  <tr key={`subs${index}`}>
                                    <td>{parseFloat(val.price).toFixed(4)} BNB</td>
                                    <td>{parseFloat(val.amount_paid).toFixed(4)} BNB</td>
                                    {/* <td>{parseFloat(val.daily_return_percentage).toFixed(2)}%</td> */}
                                    <td>{val.created_at}</td>
                                  </tr>
                                )
                              })
                            ):(
                              <tr>
                                <td colSpan="3" className="text-center">
                                  No Data Available!
                                </td>
                              </tr>
                            )
                            ) : (
                              <tr>
                                <td colSpan="3" className="text-center">
                                  No Data Available!
                                </td>
                              </tr>
                          )}
                        </tbody>
                    </table>
                    {this.state.subscriptionList ? (
                      <div className="text-center">
                        <ul className="table_pagination mb-0">
                          <li  style={{cursor:this.state.subscriptionList.prev_page_url?'pointer':'initial'}}>
                            <a onClick={()=>this.getPaginationData(this.state.subscriptionList.prev_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)}><i className="fa fa-angle-double-left"></i></a>
                          </li>
                          {this.state.subscriptionList.pages.length>0 ? (
                            this.state.subscriptionList.pages.map(function(val, index){
                              let className = "";
                              if(val == this2.state.subscriptionList.current_page){
                                className = "active";
                              }
                              return (
                                <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.subscriptionList.current_page?'pointer':'initial'}}>
                                  <a onClick={()=>this2.getPaginationData(null,'subs_page',val,null,null)} >{val}</a>
                                </li>
                              )
                            })
                          ):(null)}
                          <li style={{cursor:this.state.subscriptionList.next_page_url?'pointer':'initial'}}>
                            <a onClick={()=>this.getPaginationData(this.state.subscriptionList.next_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                          </li>
                        </ul>
                      </div>
                    ):(null)}
                  </div>
                </div>
                </div>
                <div className="tab-pane fade" id="table_tab2" role="tabpanel">
                <div className="table_box">
                    <div className="table-responsive dark_table">
                      <table className="table mb-0">
                        <thead>
                        <tr>
                          <th>Amount</th>
                          <th>Overpay Amount</th>
                          <th>Source</th>
                          <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                          {this.state.incomeList ? (
                            this.state.incomeList.data.length>0 ? (
                              this.state.incomeList.data.map(function(val, index){                        
                                return (
                                  <tr key={`income${index}`}>
                                    <td>{parseFloat(val.amount).toFixed(4)} BNB</td>
                                    <td>{parseFloat(val.overpay_amount).toFixed(4)} BNB</td>
                                    <td>{val.source}</td>
                                    <td>{val.created_at}</td>
                                  </tr>
                                )
                              })
                            ):(
                              <tr>
                                <td colSpan="4" className="text-center">
                                  No Data Available!
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center">
                                  No Data Available!
                                </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {this.state.incomeList ? (
                        <div className="text-center">
                          <ul className="table_pagination mb-0">
                            <li  style={{cursor:this.state.incomeList.prev_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.incomeList.prev_page_url,null,null,this.state.incomeList.current_page,this.state.incomeList.last_page)}><i className="fa fa-angle-double-left"></i></a>
                            </li>
                            {this.state.incomeList.pages.length>0 ? (
                              this.state.incomeList.pages.map(function(val, index){
                                let className = "";
                                if(val == this2.state.incomeList.current_page){
                                  className = "active";
                                }
                                return (
                                  <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.incomeList.current_page?'pointer':'initial'}}>
                                    <a onClick={()=>this2.getPaginationData(null,'income_page',val,null,null)} >{val}</a>
                                  </li>
                                )
                              })
                            ):(null)}
                            <li style={{cursor:this.state.incomeList.next_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.incomeList.next_page_url,null,null,this.state.incomeList.current_page,this.state.incomeList.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                            </li>
                          </ul>
                        </div>
                      ):(null)}
                    </div>
                </div>
                </div>
                <div className="tab-pane fade" id="table_tab3" role="tabpanel">
                <div className="table_box">
                    <div className="table-responsive dark_table">
                      <table className="table mb-0">
                        <thead>
                        <tr>
                          <th>Amount From</th>
                          <th>Amount To</th>
                          <th>Remarks</th>
                          <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                          {this.state.withdrawList ? (
                            this.state.withdrawList.data.length>0 ? (
                              this.state.withdrawList.data.map(function(val, index){                        
                                return (
                                  <tr key={`income${index}`}>
                                    <td>{parseFloat(val.amount_from).toFixed(4)} BNB</td>
                                    <td>{parseFloat(val.amount_to).toFixed(4)} BNB</td>
                                    <td>{val.remarks}</td>
                                    <td>{val.created_at}</td>
                                  </tr>
                                )
                              })
                            ):(
                              <tr>
                                <td colSpan="4" className="text-center">
                                  No Data Available!
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center">
                                  No Data Available!
                                </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {this.state.withdrawList?.data.length>0? (
                        <div className="text-center">
                          <ul className="table_pagination mb-0">
                            <li  style={{cursor:this.state.withdrawList.prev_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.withdrawList.prev_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)}><i className="fa fa-angle-double-left"></i></a>
                            </li>
                            {this.state.withdrawList.pages.length>0 ? (
                              this.state.withdrawList.pages.map(function(val, index){
                                let className = "";
                                if(val == this2.state.withdrawList.current_page){
                                  className = "active";
                                }
                                return (
                                  <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.withdrawList.current_page?'pointer':'initial'}}>
                                    <a onClick={()=>this2.getPaginationData(null,'withdraw_page',val,null,null)} >{val}</a>
                                  </li>
                                )
                              })
                            ):(null)}
                            <li style={{cursor:this.state.withdrawList.next_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.withdrawList.next_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                            </li>
                          </ul>
                        </div>
                      ):(null)}
                    </div>
                </div>
                </div>
                <div className="tab-pane fade" id="table_tab4" role="tabpanel">
                <div className="table_box">
                    <div className="table-responsive dark_table">
                      <table className="table mb-0">
                        <thead>
                        <tr>
                          <th>First Business</th>
                          <th>Second Business</th>
                          <th>Third Business</th>
                          <th>Matching Business</th>
                          <th>Payout</th>
                        </tr>
                        </thead>
                        <tbody>
                          {this.state.binaryPayoutLog ? (
                            this.state.binaryPayoutLog.data.length>0 ? (
                              this.state.binaryPayoutLog.data.map(function(val, index){                        
                                return (
                                  <tr key={`income${index}`}>
                                    <td>{parseFloat(val.left_business+val.prev_left_balance).toFixed(4)} BNB</td>
                                    <td>{parseFloat(val.second_business+val.prev_second_balance).toFixed(4)} BNB</td>
                                    <td>{parseFloat(val.right_business+val.prev_right_balance).toFixed(4)} BNB</td>
                                    <td>{parseFloat(val.total_payout).toFixed(4)} BNB</td>
                                    <td>{parseFloat(val.commission).toFixed(4)} BNB</td>
                                  </tr>
                                )
                              })
                            ):(
                              <tr>
                                <td colSpan="4" className="text-center">
                                  No Data Available!
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center">
                                  No Data Available!
                                </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {this.state.binaryPayoutLog ? (
                        <div className="text-center">
                          <ul className="table_pagination mb-0">
                            <li  style={{cursor:this.state.binaryPayoutLog.prev_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.binaryPayoutLog.prev_page_url,null,null,this.state.binaryPayoutLog.current_page,this.state.binaryPayoutLog.last_page)}><i className="fa fa-angle-double-left"></i></a>
                            </li>
                            {this.state.binaryPayoutLog.pages.length>0 ? (
                              this.state.binaryPayoutLog.pages.map(function(val, index){
                                let className = "";
                                if(val == this2.state.binaryPayoutLog.current_page){
                                  className = "active";
                                }
                                return (
                                  <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.binaryPayoutLog.current_page?'pointer':'initial'}}>
                                    <a onClick={()=>this2.getPaginationData(null,'binary_page',val,null,null)} >{val}</a>
                                  </li>
                                )
                              })
                            ):(null)}
                            <li style={{cursor:this.state.binaryPayoutLog.next_page_url?'pointer':'initial'}}>
                              <a onClick={()=>this.getPaginationData(this.state.binaryPayoutLog.next_page_url,null,null,this.state.binaryPayoutLog.current_page,this.state.binaryPayoutLog.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                            </li>
                          </ul>
                        </div>
                      ):(null)}
                    </div>
                </div>
                </div>
                <div className="tab-pane fade" id="table_tab5" role="tabpanel">
                <div className="table_box">
                    <div className="table-responsive dark_table">
                      <table className="table mb-0">
                        <thead>
                        <tr>
                          <th>Address</th>
                          <th >Total Direct</th>
                          <th>Package Details</th>
                          {/* <th>Current Rank</th> */}
                          <th><div style={{width:130}}>Matching Amount</div></th>
                          {/* <th><div style={{width:130}}>Next Matching Amount</div></th> */}
                          <th>Leg</th>
                        </tr>
                        </thead>
                        <tbody>
                          {this.state.DirectList ? (
                            this.state.DirectList.length>0 ? (
                              this.state.DirectList.map(function(val, index){                        
                                return (
                                  <tr key={`direct${index}`}>
                                    <td>{val.address.slice(0, 5) + '.....' + val.address.slice(val.address.length-5, val.address.length)}</td>
                                    <td>{val.direct_count}</td>
                                    <td>{parseFloat(val.total_investment).toFixed(2)} BNB</td>
                                    {/* <td>{val.profile_rank}</td> */}
                                    <td>{parseFloat(val.current_matching_business).toFixed(4)} BNB</td>
                                    {/* <td>{parseFloat(val.matching_business_required_for_next_rank).toFixed(4)} BNB</td> */}
                                    <td>{val.leg}</td>
                                  </tr>
                                )
                              })
                            ):(
                              <tr>
                                <td colSpan="4" className="text-center">
                                  No Data Available!
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center">
                                  No Data Available!
                                </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                </div>
                </div>
            </div>
          </div>
          </div>
          </section>

        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    }
  }


  export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
  //export default Home;