import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <footer className="pb-5 pt-5 background_img text-center">
        <div className="container">
          <div className="container">
            <img className="footer_logo" src="img/dark-logo.png" alt=""/>
            
            <div className="text-center">
              <p className="footer_text">OneBNB is a distinctive platform that provides investors access to the highest growth opportunities in the decentralized ecosystem. Our investment solutions are tailored to impart financial freedom with every BNB token invested. We are changing the way the world makes investments. At OneBNB, we visualize investing differently.</p>
            </div>
            <hr/>
            <p className="mb-0">© 2023 onebnb All Rights Reserved. </p>
          </div>
        </div>
      </footer>
      );
    }
  }

  export default Footer;