import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import './App.css';
import Home from "./pages/home/home";
import Wallet from "./pages/home/wallet";


class App extends React.Component {

  render() {
  return (
    <div className="App">
        <Router>
      <div>
       
        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>

        <Route exact path="/">
            <Home></Home>
          </Route>

        {/* <Route exact path="/wallet/:referral?/:side?">
            <Wallet></Wallet>
          </Route> */}
          
          

          
         
        </Switch>
      </div>
    </Router>
    </div>
  );
  }
}

export default App;
