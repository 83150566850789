
export const addAddressApi = (address,sponsor_address,userSide) =>{
    const url = 'https://1bnb.business/app/api/address-request-store';
    
    var formData = new FormData();
    formData.append('address', address);
    formData.append('sponsor_address', sponsor_address);
    formData.append('userSide', userSide);
    const options = {
        method: 'POST',
        headers: {Accept: 'application/json'},
        body:formData
    };
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                console.log('json',json);
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('amount')){
                        result.error = json.errors['amount'];
                    }
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('address')){
                        result.error = json.errors['address'];
                    }
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('sponsor_address')){
                        result.error = json.errors['sponsor_address'];
                    }
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
    })
}

export const communityDetailsApi = (address,refresh) =>{
    let param = '';
    if(refresh){
        param = '?refresh=1';
    }
    const url = 'https://1bnb.business/app/api/index-details/'+address+param;
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const packageDetailsApi = () =>{    
    const url = 'https://1bnb.business/app/api/index-all-package';
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.packages;
                    result.data.systemUserCount = json.systemUserCount;
                    result.data.systemInvest = json.systemInvest;
                    result.data.systemWithdraw = json.systemWithdraw;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const syncDataApi = (address,refresh) =>{
    let param = '';
    if(refresh){
        param = '?refresh=1';
    }
    const url = 'https://1bnb.business/app/api/total-income-sync/'+address+param;
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    if(json.hasOwnProperty('msg')){
                        result.msg = json.msg;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const dataDetailsApi = (address,fullUrl,type,page) =>{
    let url = '';
    if(fullUrl){
        url = fullUrl;
    }else{
        url = 'https://1bnb.business/app/api/details-data/'+address;
        if(type){
            url = url+'?'+type+'='+page;
        }
    }
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const compoundSubscriptionApi = (address) =>{
    
    const url = 'https://1bnb.business/app/api/compound-subscription/'+address;
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    if(json.hasOwnProperty('msg')){
                        result.msg = json.msg;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const addressDetailApi = (address) =>{
    
    const url = 'https://1bnb.business/app/api/address-detail/'+address;
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    if(json.hasOwnProperty('msg')){
                        result.msg = json.msg;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const activeBotApi = (fullUrl,type,page) =>{
    let url = '';
    if(fullUrl){
        url = fullUrl;
    }else{
        url = 'https://1bnb.business/app/api/active-bot';
        if(type){
            url = url+'?'+type+'='+page;
        }
    }
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.data){
                    result.status = true;
                    result.data = json.data;
                    result.setting = json.setting;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const tradeHistoryApi = (fullUrl,type,page) =>{
    let url = '';
    if(fullUrl){
        url = fullUrl;
    }else{
        url = 'https://1bnb.business/app/api/trade-history';
        if(type){
            url = url+'?'+type+'='+page;
        }
    }
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.data){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const tradeHistoryPairApi = (fullUrl,type,page,selected_pair) =>{
    let pair_arr = selected_pair.split('-');
    let currency = pair_arr[0];
    let url = '';
    if(fullUrl){
        url = fullUrl;
    }else{
        url = 'https://1bnb.business/app/api/trade-history';
        if(currency){
            url = url+'/'+currency;
        }
        if(type){
            url = url+'?'+type+'='+page;
        }
    }
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.data){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

// export const addressBlockListApi = () =>{
    
//     const url = 'https://1bnb.business/app/api/withdraw-block-address';
    
//     const options = {method: 'GET', headers: {Accept: 'application/json'}};
//     return new Promise( (resolve, reject) => {
//             const result = {status:false, data:[]}
//            // setInterval(()=>{
//             fetch(url, options)
//             .then(res => res.json())
//             .then((json)=>{
//                 if(json.status == 'true'){
//                     result.status = true;
//                     result.data = json.data;
//                     resolve(result);
//                 }else{
//                     if(json.hasOwnProperty('error')){
//                         result.error = json.error;
//                     }
//                     if(json.hasOwnProperty('msg')){
//                         result.msg = json.msg;
//                     }
//                     resolve(result);
//                 }
//             })
//             .catch(err => console.error('error:' + err));
//         //},10000);
//     })
// }

export const withdrawApi = (address,amount,captchaValue) =>{
    const url = 'https://1bnb.business/app/api/withdraw';
    let params = {
        address:address,
        amount:amount
    }

    var formData = new FormData();
    formData.append('address', address);
    formData.append('amount', amount);
    formData.append('g-recaptcha-response', captchaValue);

    const options = {
        method: 'POST',
        headers: {Accept: 'application/json'},
        body:formData
    };
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                console.log('json',json);
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('amount')){
                        result.error = json.errors['amount'][0];
                    }
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('address')){
                        result.error = json.errors['address'][0];
                    }
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
    })
}

// export const missingTransactionApi = (t_hash) =>{
//     const url = 'https://1bnb.business/app/api/missing-transaction';
//     let params = {
//         t_hash:t_hash
//     }

//     var formData = new FormData();
//     formData.append('t_hash', t_hash);
//     const options = {
//         method: 'POST',
//         headers: {Accept: 'application/json'},
//         body:formData
//         //body:JSON.stringify(params)
//     };
//     return new Promise( (resolve, reject) => {
//             const result = {status:false, data:[]}
//             fetch(url, options)
//             .then(res => res.json())
//             .then((json)=>{
//                 console.log('json',json);
//                 if(json.status == 'true'){
//                     result.status = true;
//                     result.data = json.data;
//                     result.message = json.message;
//                     resolve(result);
//                 }else{
//                     if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('t_hash')){
//                         result.error = json.errors['t_hash'];
//                     }
//                     if(json.hasOwnProperty('error')){
//                         result.error = json.error;
//                     }
//                     if(json.hasOwnProperty('message')){
//                         result.error = json.message;
//                     }
//                     resolve(result);
//                 }
//             })
//             .catch(err => console.error('error:' + err));
//     })
// }

// export const networkSettingApi = (address,default_leg) =>{
//     const url = 'https://1bnb.business/app/api/network-settings';
//     let params = {
//         address:address
//     }

//     var formData = new FormData();
//     formData.append('address', address);
//     formData.append('default_leg', default_leg);
//     const options = {
//         method: 'POST',
//         headers: {Accept: 'application/json'},
//         body:formData
//         //body:JSON.stringify(params)
//     };
//     return new Promise( (resolve, reject) => {
//             const result = {status:false, data:[]}
//             fetch(url, options)
//             .then(res => res.json())
//             .then((json)=>{
//                 console.log('json',json);
//                 if(json.status == 'true'){
//                     result.status = true;
//                     result.data = json.data;
//                     result.message = json.message;
//                     resolve(result);
//                 }else{
//                     if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('t_hash')){
//                         result.error = json.errors['t_hash'];
//                     }
//                     if(json.hasOwnProperty('error')){
//                         result.error = json.error;
//                     }
//                     if(json.hasOwnProperty('message')){
//                         result.error = json.message;
//                     }
//                     resolve(result);
//                 }
//             })
//             .catch(err => console.error('error:' + err));
//     })
// }